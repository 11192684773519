<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/daughter-2">我女兒懷孕了</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">週數多大無法中止懷孕</a></li>
            </ol>
          </nav>
        </section>
        <!-- 主要區塊 -->

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜週數多大無法中止懷孕｜</h2>
                </div>
                <div class="step-header">
                  <img data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                    <p data-aos="fade-up">若您的女兒懷孕未滿 24 週，若決定終止懷孕，
                        在進行人工流產前您有權獲得充分的醫療資訊，
                        可要求婦產科醫護人員提供充分諮詢，包括適用的人工流產方式，
                        手術前後應注意事項、在身體、心理及社會層面上的自我照顧以及避孕資訊等。
                        若您的女兒已婚，根據法律規定，行人工流產前需要配偶同意，夫妻一起做出有共識的決定。
                        終止懷孕包括服用藥物、進行人工流產手術或引產3種方式，需依照懷孕週數來決定流產方式。
                        因月經未準時來潮而發現懷孕通常已是 4 週後，因此是否終止懷孕的考量也是從懷孕第 5 週開始。
                    </p>
                    <img data-aos="fade-up" class="rounded-0 w-100" src="https://www.taog.org.tw/img/ill/12.jpg" alt="人工流產">
                  <hr data-aos="fade-up">
                </div>
                <div class="select-menu">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>施行人工流產的方式及風險預後</h3>
                        <ul class="list">
                          <li>1.藥物流產：<br>
                          在門診服用A藥物(mifepristone)，兩天後回診服用B藥物(misoprostol)
                          </li>
                          <li>2.抽吸式子宮內容物吸取方式：<br>
                            將胎兒移除，一般須麻醉下執行。</li>
                          <li>3.住院藥物引產：<br>
                            為較大週數胎兒，
                            住院後利用藥物引起子宮收縮
                            娩出胎兒。
                          </li>
                          <p class="mt-0">上述方式為建議原則，請與醫護人員討論後執行。</p>
                        </ul>
                      </li>
                      <li data-aos="fade-up">
                        <h3>優生保健法規定，人工流產應於懷孕24週內，在有施行人工流產醫師之醫院住院施行。<br>
                          若超過24週進行人工流產，除了屬非法行為，孕婦也有致死的可能。<br>
                          但產婦若有下列法條之情形，則不在此限：</h3>
                          <ul class="list mb-60">
                            <li>(1)本人或其配偶患有礙優生之遺傳性、傳染性疾病或精神疾病者。</li>
                            <li>(2)本人或其配偶之四親等以內之血親患有礙優生之遺傳性疾病者。</li>
                            <li>(3)有醫學上理由，足以認定懷孕或分娩有招致生命危險或危害身體或精神健康者。</li>
                            <li>(4)有醫學上理由，足以認定胎兒有畸型發育之虞者。</li>
                            <li>(5)因被強制性交、誘姦或與依法不得結婚者相姦而受孕者。</li>
                            <li>(6)因懷孕或生產將影響其心理健康或家庭生活者。</li>
                          </ul>
                      </li>
                      <li data-aos="fade-up">
                        <h3>心理問題</h3>
                        <p>小媽媽經歷人工流產的創傷經驗常會使其焦慮、害怕、傷痛、有罪惡感等，亦可能產生輕生念頭，故需要家人、社福團體、心理諮商等支持和協助，引導其紓發情緒與感受，陪伴其度過此歷程。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>資料來源：</h3>
                        <p>台灣婦產科醫學會<br>財團法人勵馨社會福利事業基金會</p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
import Article from '../../components/Article.vue'

export default {
  data () {
    return {
    }
  },
  mounted () {

  },
  components: {
    Consult,
    Article
  }
}
</script>
