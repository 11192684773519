<template>
  <div>
    <section class="article">
        <div class="article-container">
            <h2 data-aos="fade-up">-相關主題-</h2>
            <p class="article-subTitle" data-aos="fade-up">相關文章推薦</p>
            <ul class="d-flex flex-wrap">
                <!--<li data-aos="fade-up">
                   <a href="http://www.8181.org.tw/contraception-guide/know-cont.asp?id=204" target="_blank">
                        <img src="http://www.8181.org.tw/2020EDM/06.jpg">
                    </a>
                    <a href="http://www.8181.org.tw/contraception-guide/know-cont.asp?id=204" target="_blank">
                        <small>2020-12-01</small>
                        <p>避子小姐衛教保健室：排卵後吃事後避孕藥...無效！</p>
                    </a>
                </li>-->
                <li data-aos="fade-up">
                    <a href="https://www.health.taichung.gov.tw/1547981/post" target="_blank">
                       <img src="https://www.health.taichung.gov.tw/media/938574/%E5%AA%BD%E5%AA%BD%E5%8D%A1_2.jpg?width=360.36283672347446&height=500">
                    </a>
                    <a href="https://www.health.taichung.gov.tw/1547981/post" target="_blank">
                      <small>2020-11-05</small>
                      <p>【健康小衛星 - 好孕包】</p>
                    </a>
                </li>
                <!--<li data-aos="fade-up">
                    <a href="http://www.8181.org.tw/contraception-guide/know-cont.asp?id=203" target="_blank">
                       <img src="http://www.8181.org.tw/2020EDM/combo.jpg">
                    </a>
                    <a href="http://www.8181.org.tw/contraception-guide/know-cont.asp?id=203" target="_blank">
                      <small>2020-11-27</small>
                      <p>避子小姐衛教保健室：超實用避孕小辭典</p>
                    </a>
                </li>-->
            </ul>
        </div>
    </section>
  </div>
</template>
